  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  @layer base {
    :root {
      --background: 0 0% 95%;
      --foreground: 0 0% 3.9%;

      --card: 0, 0%, 100%, 1;
      --card-foreground: 0 0% 3.9%;
  
      --popover: 0 0% 100%;
      --popover-foreground: 0 0% 3.9%;
  
      --primary: 0 0% 9%;
      --primary-foreground: 0 0% 98%;
  
      --secondary: 0 0% 96.1%;
      --secondary-foreground: 0 0% 9%;
  
      --muted: 0 0% 96.1%;
      --muted-foreground: 0 0% 45.1%;
  
      --accent: 0 0% 96.1%;
      --accent-foreground: 0 0% 9%;
  
      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --border: 0 0% 89.8%;
      --input: 0 0% 89.8%;
      --ring: 0 0% 3.9%;
  
      --radius: 0.5rem;

      --green: hsl(142, 71%, 45%);
      --yellow: hsl(45, 93%, 47%);
      --red: hsl(0, 84%, 60%);

      --map-filter: grayscale(100%) invert(0%);
    }
  
    .dark {
      --background: 0 0% 10.9%;
      --foreground: 0 0% 98%;
  
      --card: 0, 0%, 8%;
      --card-foreground: 0 0% 98%;
  
      --popover: 0 0% 3.9%;
      --popover-foreground: 0 0% 98%;
  
      --primary: 0 0% 98%;
      --primary-foreground: 0 0% 9%;
  
      --secondary: 0 0% 14.9%;
      --secondary-foreground: 0 0% 98%;
  
      --muted: 0 0% 14.9%;
      --muted-foreground: 0 0% 63.9%;
  
      --accent: 0 0% 14.9%;
      --accent-foreground: 0 0% 98%;
  
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;
  
      --border: 0 0% 14.9%;
      --input: 0 0% 14.9%;
      --ring: 0 0% 83.1%;

      --green: hsl(144, 61%, 20%);
      --yellow: hsl(41, 96%, 40%);
      --red: hsl(0, 63%, 31%);

      --map-filter: grayscale(100%) invert(100%);
    }
  }
  
  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }

  .bg {
    background-color: hsl(var(--background));
  }

  .leaflet-tile-pane, .leaflet-control {
    filter: var(--map-filter);
  }

  .timeline-distance {
    transform: translateY(-1.8rem);
    position: absolute;
    right: 0;
  }

  .tour-date-item-data.dot {
    min-height: 2.5rem;
  }

  .timeline .tour-date-item .tour-date-item-options {
    top: 1rem;
    right: 0px;
    top: 0;
  }

  .tour-date-item .tour-date-item-options, .tour-date-row .tour-date-item-options {
    visibility: hidden;
    opacity: 0;
  }

  .tour-date-item:hover .tour-date-item-options, .tour-date-row:hover .tour-date-item-options {
    visibility: visible;
    opacity: 1;
  }
  
  .tour-date-item:first-of-type .tour-date-item-meta::after {
    border-right: none;
  }

  .tour-date-item-meta::after {
    content: "";
    width: 1px;
    border-right: 2px hsl(var(--border)) solid;
    z-index: 1;
    position: absolute;
    top: -2.5rem;
    bottom: 2.5rem;
    right: 0px;
  }

  .tour-date-item-meta.border-warning::after {
    border-right: 2px var(--yellow) solid;
    opacity: 0.3;
  }

  .tour-date-item-meta.border-dotted::after {
    border-right: 2px hsl(var(--border)) dotted;
  }

  .dot::before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    background: hsl(var(--border));
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    transform: translate(calc(-1.65rem - 1px), 0.35rem);
  }

  .tour-date-item-data.dot::before {
    outline: 3px solid hsl(var(--card));
  }

  .dot-green::before,
  .dot-yellow::before,
  .dot-red::before {
    width: 1rem;
    height: 1rem;
    transform: translate(calc(-1.75rem - 1px), 0.25rem);
  }

  .dot-green::before {
    background: var(--green);
  }

  .dot-yellow::before {
    background: var(--yellow);
  }

  .dot-red::before {
    background: var(--red);
  }

  .text-green {
    color: var(--green);
  }

  .text-yellow {
    color: var(--yellow);
  }

  .text-red {
    color: var(--red);
  }